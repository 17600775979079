import { Report } from '../../types';


// noinspection SpellCheckingInspection
export const codes = {
    /**
     * Префикс ключей доплат
     */
    addPaymentPrefix: 'c__ead__ap__',

    /**
     * Ключ группы "Сумма доплат"
     */
    addPaymentTotal: 'c__ap_total',

    /**
     * Префикс ключей надбавок
     */
    allowancePrefix: 'c__ead__al__',

    /**
     * Ключ группы "Сумма надбавок"
     */
    allowanceTotal: 'c__al_total',

    /**
     * Ключ для "Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц"
     */
    AP_FMAaMCfMDaPUpM: 'c__ead__misc__ap_fmaamcfmdapupm',

    /**
     * Ключ для "Оклад по воинским званиям"
     */
    AP_MILITARY_RANK_SALARY: 'c__ead__misc__ap_military_rank_salary',
};

export const nonClearableFields = new Set<string>(['id', 'order_number', 'debug_info']);

export const ignoredFields = new Set<string>(['__ob__']);


export const getColumnFields = (columns: Array<Report.Version2.Col>): Set<string> => {
    return new Set(columns.map(column => column.field));
};

export const getColumnForSubprogram = (colGroup: Report.SubprogramDist.ColGroup, subprogram: number | null): Report.Version2.Col | null => {
    let column: Report.Version2.Col | undefined;
    if (subprogram === null) {
        column = (colGroup.emptySubprogram || colGroup.columnMapBySubprograms.get(null));
    } else {
        column = colGroup.columnMapBySubprograms.get(subprogram);
    }

    if (column === undefined) return null;
    else return column;
};

export const copyRow = (source: Record<string, unknown>): Record<string, unknown> => {
    const result: Record<string, unknown> = {};
    Object.getOwnPropertyNames(source).forEach(field => {
        if (ignoredFields.has(field)) return;
        result[field] = source[field];
    });
    return result;
};

export const clearRow = (columns: Array<Report.Version2.Col>, target: Record<string, unknown>, preparedFields?: Set<string>) => {
    const fields = (preparedFields || getColumnFields(columns));

    const removedFields: Array<string> = [];
    Object.getOwnPropertyNames(target).forEach(field => {
        if ((!fields.has(field)) && (!nonClearableFields.has(field)) && (!ignoredFields.has(field))) {
            removedFields.push(field);
        }
    });
    removedFields.forEach(field => {
        delete target[field];
    });
};

export const readNumber = (source: Record<string, unknown>, field: string): number | null => {
    const value = source[field];
    if ((typeof value === 'number') && Number.isFinite(value)) return value;
    else return null;
};

export const readNumberForSubprogram = (source: Record<string, unknown>, colGroup: Report.SubprogramDist.ColGroup, subprogram: number | null): number | null => {
    const column = getColumnForSubprogram(colGroup, subprogram);
    if (column === null) return null;
    else return readNumber(source, column.field);
};

export const setNumber = (target: Record<string, unknown>, field: string, value?: number | null) => {
    if ((typeof value === 'number') && Number.isFinite(value)) target[field] = value;
    else delete target[field];
};

export const setNumberForSubprogram = (target: Record<string, unknown>, colGroup: Report.SubprogramDist.ColGroup, subprogram: number | null, value?: number | null) => {
    const column = getColumnForSubprogram(colGroup, subprogram);
    if (column !== null) setNumber(target, column.field, value);
};


export const getColGroupMapByGroupCode = (columns: Array<Report.Version2.Col>): Map<string, Report.SubprogramDist.ColGroup> => {
    const result: Map<string, Report.SubprogramDist.ColGroup> = new Map();

    columns.forEach(column => {
        if (column.specType === 'SUBPROG_DIST') {
            const code = column.subprogDistGroupCode;
            if (code !== null) {
                let group: Report.SubprogramDist.ColGroup | undefined = result.get(code);
                if (group === undefined) {
                    group = { code, columnMapBySubprograms: new Map() };
                    result.set(code, group);
                }

                switch (column.subprogDistGroupType) {
                    case 'MAX':
                        group.max = column;
                        break;
                    case 'SUBPROGRAM_READONLY':
                        if (column.subprogram === null) {
                            group.emptySubprogram = column;
                        }
                        group.columnMapBySubprograms.set(column.subprogram, column);
                        break;
                    case 'SUBPROGRAM_EDITABLE':
                        if (column.subprogram !== null) {
                            group.columnMapBySubprograms.set(column.subprogram, column);
                        }
                        break;
                }
            }
        }
    });

    return result;
};

export const getSubprograms = (columns: Array<Report.Version2.Col>): Array<number> => {
    const result: Array<number> = [];
    columns.forEach(column => {
        const subprogram = column.subprogram;
        if ((subprogram !== null) && (!result.includes(subprogram))) {
            result.push(subprogram);
        }
    });
    return result;
};

export const applySubprogramsToColumns = (subprograms: Array<number>, columns: Array<Report.Version2.Col>): Array<Report.Version2.Col> => {
    const result: Array<Report.Version2.Col> = [...columns];

    const colGroupMapByGroupCode = getColGroupMapByGroupCode(result);

    colGroupMapByGroupCode.forEach(colGroup => {
        const emptySubprogram = colGroup.emptySubprogram;
        if (emptySubprogram === undefined) {
            throw new Error(`Cannot find column for empty subprogram in column group with code "${colGroup.code}"`);
        }

        if (!colGroup.columnMapBySubprograms.has(null)) {
            colGroup.columnMapBySubprograms.set(null, emptySubprogram);
        }

        const removedSubprograms: Array<number> = [];
        colGroup.columnMapBySubprograms.forEach((column, subprogram) => {
            if (subprogram === null) return;
            if (!subprograms.includes(subprogram)) {
                removedSubprograms.push(subprogram);
            }
        });

        subprograms.forEach(subprogram => {
            if (!colGroup.columnMapBySubprograms.has(subprogram)) {
                const column: Report.Version2.Col = {
                    id: null,
                    sheet: emptySubprogram.sheet,
                    orderNumber: 0,
                    titleKk: emptySubprogram.titleKk + ` (подпрограмма ${subprogram})`,
                    titleRu: emptySubprogram.titleRu + ` (подпрограмма ${subprogram})`,
                    subtitleKk: emptySubprogram.subtitleKk,
                    subtitleRu: emptySubprogram.subtitleRu,
                    field: emptySubprogram.field + '@' + String(subprogram),
                    dateField: emptySubprogram.dateField,
                    specType: emptySubprogram.specType,
                    subprogram: subprogram,
                    hidden: null,
                    excelWidth: emptySubprogram.excelWidth,
                    useIntegerFormat: emptySubprogram.useIntegerFormat,
                    subprogDistGroupCode: emptySubprogram.subprogDistGroupCode,
                    subprogDistGroupType: (colGroup.max === undefined ? 'SUBPROGRAM_READONLY' : 'SUBPROGRAM_EDITABLE'),
                    resultCol: emptySubprogram.resultCol,
                };
                colGroup.columnMapBySubprograms.set(subprogram, column);
            }
        });

        let allColumns: Array<Report.Version2.Col>;
        let columnMinIndex = Number.MAX_SAFE_INTEGER;

        allColumns = [];
        if (colGroup.max !== undefined) allColumns.push(colGroup.max);
        if (colGroup.emptySubprogram !== undefined) allColumns.push(colGroup.emptySubprogram);
        allColumns.push(...colGroup.columnMapBySubprograms.values());
        allColumns.forEach(column => {
            const index = result.indexOf(column);
            if (index >= 0) {
                result.splice(index, 1);
                if (columnMinIndex > index) columnMinIndex = index;
            }
        });

        removedSubprograms.forEach(subprogram => { colGroup.columnMapBySubprograms.delete(subprogram); });

        allColumns = [];
        if (colGroup.max !== undefined) allColumns.push(colGroup.max);
        if (colGroup.emptySubprogram !== undefined) allColumns.push(colGroup.emptySubprogram);
        subprograms.forEach(subprogram => {
            const column = colGroup.columnMapBySubprograms.get(subprogram);
            if (column !== undefined) allColumns.push(column);
        });
        result.splice(columnMinIndex, 0, ...allColumns);
    });

    result.forEach((column, index) => { column.orderNumber = index; });

    return result;
};


export const applyChange = (columns: Array<Report.Version2.Col>, row: Record<string, unknown>, change: Record<string, unknown>): Record<string, unknown> => {
    const fields = new Set<string>(columns.map(column => column.field));

    const result: Record<string, unknown> = copyRow(row);

    Object.getOwnPropertyNames(change).forEach(field => {
        if (fields.has(field)) {
            const value = change[field];
            if ((value === undefined) || (value === null)) {
                delete result[field];
            } else {
                result[field] = value;
            }
        }
    });

    return result;
};

export const recalculateGenericAddPaymentTotals = (sourceColGroups: Array<Report.SubprogramDist.ColGroup>, totalColGroup: Report.SubprogramDist.ColGroup, target: Record<string, unknown>) => {
    const totalMapBySubprogram = new Map<number | null, number>();

    const append = (subprogram: number | null, value: number) => {
        const prev = (totalMapBySubprogram.get(subprogram) || 0);
        const next = (prev + value);
        totalMapBySubprogram.set(subprogram, next);
    };

    sourceColGroups.forEach(colGroup => {
        if (colGroup.emptySubprogram !== undefined) {
            const value = readNumber(target, colGroup.emptySubprogram.field);
            if (value !== null) append(null, value);
        }

        colGroup.columnMapBySubprograms.forEach((column, subprogram) => {
            if (subprogram === null) return;

            const value = readNumber(target, column.field);
            if (value !== null) append(subprogram, value);
        });
    });

    if (totalColGroup.emptySubprogram !== undefined) {
        const value = totalMapBySubprogram.get(null);
        setNumber(target, totalColGroup.emptySubprogram.field, value);
    }
    totalColGroup.columnMapBySubprograms.forEach((column, subprogram) => {
        if (subprogram === null) return;

        const value = totalMapBySubprogram.get(subprogram);
        setNumber(target, column.field, value);
    });
};

export const recalculateColGroupsAndAddPayments = (colGroups: Map<string, Report.SubprogramDist.ColGroup>, target: Record<string, unknown>) => {
    const addPaymentColGroups: Array<Report.SubprogramDist.ColGroup> = [];
    const allowanceColGroups: Array<Report.SubprogramDist.ColGroup> = [];
    let addPaymentTotalColGroup: Report.SubprogramDist.ColGroup | undefined = undefined;
    let allowanceTotalColGroup: Report.SubprogramDist.ColGroup | undefined = undefined;

    // recalculate inside column groups
    colGroups.forEach((colGroup, groupCode) => {
        if (groupCode.startsWith(codes.addPaymentPrefix)) addPaymentColGroups.push(colGroup);
        else if (groupCode.startsWith(codes.allowancePrefix)) allowanceColGroups.push(colGroup);
        else if (groupCode === codes.addPaymentTotal) addPaymentTotalColGroup = colGroup;
        else if (groupCode === codes.allowanceTotal) allowanceTotalColGroup = colGroup;

        const maxColumn = colGroup.max;
        if (maxColumn === undefined) return;

        const maxValue = readNumber(target, maxColumn.field);
        if (maxValue === null) return;

        const emptySubprogramColumn = colGroup.emptySubprogram;
        if (emptySubprogramColumn === undefined) return;

        /**
         * Про переменные с именами "***N" и/или с типом BigInt ниже в этом цикле.
         * ----
         * В браузере есть проблема с точностью чисел, например
         *     2.212-1.212
         * дает не "1", а "1.0000000000000002".
         * Поэтому применено округление до "десятитысячных" (до "трех знаков после запятой"): умножить на 1000, повычислять, округлить, поделить на 1000.
         * BigInt здесь используется для контроля - чтоб "десятитысячные" доли точно были целыми числами.
         * ----
         * Хз, будут ли опять ошибки из-за этой же проблемы
         */

        let otherSubprogramTotal = 0;
        const maxValueN = BigInt(Math.round(maxValue * 1000));
        let totalN = BigInt(0);
        colGroup.columnMapBySubprograms.forEach((column, subprogram) => {
            if (subprogram === null) return;

            const value = readNumber(target, column.field);
            if (value !== null) {
                otherSubprogramTotal += value;
                totalN += BigInt(Math.round(value * 1000));
            }
        });

        setNumber(target, emptySubprogramColumn.field, Number(maxValueN - totalN) / 1000);
    });

    // recalculate add. payment totals
    if (addPaymentTotalColGroup !== undefined) recalculateGenericAddPaymentTotals(addPaymentColGroups, addPaymentTotalColGroup, target);

    // recalculate allowance totals
    if (allowanceTotalColGroup !== undefined) recalculateGenericAddPaymentTotals(allowanceColGroups, allowanceTotalColGroup, target);
};

export const createDefaultController = (
    recalculate: (
        columns: Array<Report.Version2.Col>,
        row: Record<string, unknown>,
        preparedColGroups?: Map<string, Report.SubprogramDist.ColGroup>,
        preparedSubprograms?: Array<number>,
        preparedFields?: Set<string>,
    ) => Record<string, unknown>,
): Report.SubprogramDist.Controller => {
    return {
        applyChange(columns: Array<Report.Version2.Col>, row: Record<string, unknown>, change: Record<string, unknown>): Record<string, unknown> {
            const changedRow = applyChange(columns, row, change);
            return recalculate(columns, changedRow);
        },

        subprogramsChanged(oldSubprograms: Array<number>, newSubprograms: Array<number>, data: Report.SubprogramDist.Data): Report.SubprogramDist.Data {
            const columns = applySubprogramsToColumns(newSubprograms, data.columns);
            const colGroups = getColGroupMapByGroupCode(columns);
            const fields = getColumnFields(columns);

            const rows: Array<Record<string, unknown>> = data.rows.map(row => recalculate(columns, row, colGroups, newSubprograms, fields));

            return { columns, rows };
        },
    };
};

export const recalculateWithoutSpecificCalculations = (
    columns: Array<Report.Version2.Col>,
    row: Record<string, unknown>,
    preparedColGroups?: Map<string, Report.SubprogramDist.ColGroup>,
    preparedSubprograms?: Array<number>,
    preparedFields?: Set<string>,
): Record<string, unknown> => {
    const colGroups = (preparedColGroups || getColGroupMapByGroupCode(columns));
    const result: Record<string, unknown> = copyRow(row);

    recalculateColGroupsAndAddPayments(colGroups, result);

    // Никаких перерасчетов нет

    clearRow(columns, result, preparedFields);

    return result;
};

export const controllerWithoutSpecificCalculations: Report.SubprogramDist.Controller = createDefaultController(recalculateWithoutSpecificCalculations);


export const createControllerForSubprogramRecalculation = <T>(
    createShared: (
        columns: Array<Report.Version2.Col>,
        colGroups: Map<string, Report.SubprogramDist.ColGroup>,
        subprograms: Array<number>,
        fields: Set<string>,
    ) => T,
    recalculateForSubprogram: (
        readNumber: (colGroup: Report.SubprogramDist.ColGroup | undefined) => number | null,
        setNumber: (colGroup: Report.SubprogramDist.ColGroup | undefined, value?: number | null) => void,
        columns: Array<Report.Version2.Col>,
        colGroups: Map<string, Report.SubprogramDist.ColGroup>,
        subprograms: Array<number>,
        fields: Set<string>,
        shared: T,
        subprogram: number | null,
        row: Record<string, unknown>,
    ) => void,
): Report.SubprogramDist.Controller => {
    const recalculateImpl = (
        columns: Array<Report.Version2.Col>,
        row: Record<string, unknown>,
        preparedColGroups?: Map<string, Report.SubprogramDist.ColGroup>,
        preparedSubprograms?: Array<number>,
        preparedFields?: Set<string>,
    ): Record<string, unknown> => {
        const colGroups = (preparedColGroups || getColGroupMapByGroupCode(columns));
        const subprograms = (preparedSubprograms || getSubprograms(columns));
        const fields = (preparedFields || getColumnFields(columns));
        const result: Record<string, unknown> = copyRow(row);

        const shared = createShared(columns, colGroups, subprograms, fields);

        recalculateColGroupsAndAddPayments(colGroups, result);

        const recalculateForSubprogramImpl = (subprogram: number | null): void => {
            const readNumber = (colGroup: Report.SubprogramDist.ColGroup | undefined): number | null => {
                if (colGroup === undefined) return null;
                else return readNumberForSubprogram(result, colGroup, subprogram);
            };

            const setNumber = (colGroup: Report.SubprogramDist.ColGroup | undefined, value?: number | null): void => {
                if (colGroup === undefined) return;
                setNumberForSubprogram(result, colGroup, subprogram, value);
            };

            recalculateForSubprogram(readNumber, setNumber, columns, colGroups, subprograms, fields, shared, subprogram, row);
        };

        recalculateForSubprogramImpl(null);
        subprograms.forEach(recalculateForSubprogramImpl);

        clearRow(columns, result, fields);

        return result;
    };

    return {
        applyChange(columns: Array<Report.Version2.Col>, row: Record<string, unknown>, change: Record<string, unknown>): Record<string, unknown> {
            const changedRow = applyChange(columns, row, change);
            return recalculateImpl(columns, changedRow);
        },

        subprogramsChanged(oldSubprograms: Array<number>, newSubprograms: Array<number>, data: Report.SubprogramDist.Data): Report.SubprogramDist.Data {
            const columns = applySubprogramsToColumns(newSubprograms, data.columns);
            const colGroups = getColGroupMapByGroupCode(columns);
            const fields = getColumnFields(columns);

            const rows: Array<Record<string, unknown>> = data.rows.map(row => recalculateImpl(columns, row, colGroups, newSubprograms, fields));

            return { columns, rows };
        },
    };
};