
































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ax } from "@/utils";
import I18n from '../../I18n';
import { Dict, Report } from '../../types';
import controllers from "./constrollers";
import * as common from "./controllers-common";
import Report2Row from './Report2Row.vue';
import { BvModalEvent } from "bootstrap-vue";


interface RowComponent {
    getChangedRow(): Record<string, unknown>;
    resetRow(): void;
    canTakeValueFromNullSubprogram(column: Report.Version2.Col): boolean;
    takeValueFromNullSubprogram(column: Report.Version2.Col): void;
}


const i18n = new I18n('modules.budget.staffing_table.old_v1_reports.*Report2SubprogramNoDistSheet*');
const orderNumberFieldKey = 'order_number';
const eventBudgetProgramChanged = 'budget-program-changed';


@Component({
    components: {
        'row': Report2Row,
    },
})
export default class Report2SubprogramNoDistSheet extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: true,
    })
    public readonly report!: Report;

    @Prop({
        type: Object,
        required: true,
    })
    public readonly sheet!: Report.Version2.Sheet;

    public get sheetId(): number | null {
        return this.sheet.id;
    }

    @Prop({
        type: Boolean,
        default: false,
        required: false,
    })
    public readonly debug!: boolean;

    @Prop({
        type: Number,
        required: true
    })
    public readonly reportDate!: number;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcGroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcSubgroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly abp!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly budgetProgram!: Dict.EbkFunc | null;

    public get subprogramReloadTrigger(): [number, Dict.EbkFunc | null, Dict.EbkFunc | null, Dict.EbkFunc | null, Dict.EbkFunc | null, Report.Version2.Sheet] {
        return [
            this.reportDate,
            this.funcGroup,
            this.funcSubgroup,
            this.abp,
            this.budgetProgram,
            this.sheet,
        ];
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols, JSMethodCanBeStatic
    private created() {
        this.$watch('sheetId', () => {
            this.reloadSubprogramsIfPossible();
            this.reloadColumnsIfPossible();
            this.reloadRowsIfPossible();
        });
        this.$watch('subprogramReloadTrigger', () => { this.reloadSubprogramsIfPossible(); });
        this.$watch('rows', () => {
            setTimeout(
                () => {
                    this.updateRowComponents();
                },
                1000,
            );
        });
    }

    // noinspection JSUnusedLocalSymbols, JSMethodCanBeStatic
    private mounted() {
        this.reloadSubprogramsIfPossible();
        this.reloadColumnsIfPossible();
        this.reloadRowsIfPossible();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;
    private orderNumberFieldKey = orderNumberFieldKey;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get numberFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.i18n.locale, { maximumFractionDigits: 10 });
    }

    private get subprogramDistController(): Report.SubprogramDist.Controller | null {
        const report = this.report;
        const result = controllers.get(report.form);
        return (result || null);
    }
    // endregion


    // region Подпрограммы
    private loadingSubprograms = false;
    private subprograms: Array<Dict.EbkFunc> = [];
    private subprogramsModalVisible = false;
    private modalSubprogram: Dict.EbkFunc | null = null;
    // noinspection JSMismatchedCollectionQueryUpdate
    private modalAvailableSubprograms: Array<Dict.EbkFunc> = [];

    private get modalSubprogramChanged(): boolean {
        return (this.selectedSubprogram?.id !== this.modalSubprogram?.id);
    }

    private get selectedSubprogram(): Dict.EbkFunc | null {
        for (const subprogram of this.subprograms) {
            if (subprogram.ppr === this.sheet.budgetSubprogram) {
                return subprogram;
            }
        }
        return null;
    }

    private reloadSubprogramsIfPossible() {
        if (
            (!this.loadingSubprograms)
            && (typeof this.funcGroup?.gr === 'number')
            && (typeof this.funcSubgroup?.pgr === 'number')
            && (typeof this.abp?.abp === 'number')
            && (typeof this.budgetProgram?.prg === 'number')
        ) {
            this.reloadSubprograms();
        }
    }

    private reloadSubprograms() {
        if (this.loadingSubprograms) {
            console.error('Cannot load subprograms - another loading is running');
            return;
        }

        const funcGroupCode = this.funcGroup?.gr;
        if (typeof funcGroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional group is null');
            return;
        }

        const funcSubgroupCode = this.funcSubgroup?.pgr;
        if (typeof funcSubgroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional subgroup is null');
            return;
        }

        const abpCode = this.abp?.abp;
        if (typeof abpCode !== 'number') {
            console.error('Cannot load budget subprograms - ABP is null');
            return;
        }

        const budgetProgramCode = this.budgetProgram?.prg;
        if (typeof budgetProgramCode !== 'number') {
            console.error('Cannot load budget subprograms - budget program is null');
            return;
        }

        this.loadingSubprograms = true;
        this.subprograms = [];
        this.modalSubprogram = null;
        this.modalAvailableSubprograms = [];
        Ax<Dict.EbkFunc[]>(
            {
                url: '/api/budget/staffing_table/report/budget-subprograms'
                    + `?func-group-code=${funcGroupCode}&func-subgroup-code=${funcSubgroupCode}`
                    + `&abp-code=${abpCode}&budget-program-code=${budgetProgramCode}`
                    + `&date=${this.reportDate}`
            },
            (data) => {
                const subprograms = [...data];
                subprograms.sort((subprogram1, subprogram2) => {
                    const code1 = (subprogram1.ppr === null ? 0 : subprogram1.ppr);
                    const code2 = (subprogram2.ppr === null ? 0 : subprogram2.ppr);
                    return (code1 - code2);
                });
                this.subprograms = subprograms;

                for (const subprogram of this.subprograms) {
                    if (subprogram.id === this.selectedSubprogram?.id) {
                        this.modalSubprogram = this.selectedSubprogram;
                        break;
                    }
                }
            },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_load_subprograms', [this.sheetId]), error.toString()),
            () => { this.loadingSubprograms = false; }
        );
    }

    private getSubprogramText(subprogram: Dict.EbkFunc): string {
        const code = subprogram.ppr || subprogram.id;

        let title: string;
        if (this.i18n.isKazakh) {
            title = subprogram.nameKk || subprogram.shortNameKk || String(code);
        } else {
            title = subprogram.nameRu || subprogram.nameRu || String(code);
        }
        return `${code} - ${title}`;
    }

    private saveSubprogram() {
        const subprogramCode: number | null = this.modalSubprogram?.ppr || null;
        this.$emit(eventBudgetProgramChanged, subprogramCode);
    }

    private showSubprogramsModal() {
        this.modalSubprogram = this.selectedSubprogram;
        this.modalAvailableSubprograms = this.subprograms.filter(subprogram => (subprogram.id !== this.selectedSubprogram?.id));
        this.subprogramsModalVisible = true;
    }

    private onSubprogramsModalHide(ev: BvModalEvent) {
        if (ev.trigger === 'ok') this.saveSubprogram();
    }

    private onRemoveSubprogram() {
        this.modalSubprogram = null;
        this.modalAvailableSubprograms = this.subprograms;
    }

    private onSelectSubprogram(subprogram: Dict.EbkFunc) {
        this.modalSubprogram = subprogram;
        this.modalAvailableSubprograms = this.subprograms.filter(testSubprogram => (testSubprogram.id !== subprogram.id));
    }
    // endregion


    // region Колонки
    private loadingColumns = false;
    private columns: Array<Report.Version2.Col> = [];

    private get visibleColumns(): Array<Report.Version2.Col> {
        if (this.debug) {
            return this.columns;
        } else {
            const result: Array<Report.Version2.Col> = [];
            this.columns.forEach(column => {
                if (column.hidden !== true) {
                    result.push(column);
                }
            });
            return result
        }
    }

    private get columnFields(): Set<string> {
        return common.getColumnFields(this.columns);
    }


    private reloadColumnsIfPossible() {
        if ((!this.loadingColumns) && (this.sheetId !== null)) this.reloadColumns();
    }

    private reloadColumns() {
        if (this.loadingColumns) {
            console.error('Cannot reload columns - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot reload columns - sheet ID is null');
            return;
        }


        this.loadingColumns = true;
        this.columns = [];
        Ax<Report.Version2.Col[]>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/columns` },
            data => {
                const orderNumberColumn: Report.Version2.Col = {
                    id: null,
                    sheet: null,
                    orderNumber: -1,
                    titleKk: '№',
                    titleRu: '№',
                    subtitleKk: '',
                    subtitleRu: '',
                    field: this.orderNumberFieldKey,
                    dateField: false,
                    specType: null,
                    subprogram: null,
                    hidden: null,
                    excelWidth: null,
                    useIntegerFormat: null,
                    subprogDistGroupCode: null,
                    subprogDistGroupType: null,
                    resultCol: false,
                };

                const columns: Report.Version2.Col[] = [orderNumberColumn];
                columns.push(...data);
                columns.sort((column1, column2) => (column1.orderNumber - column2.orderNumber));

                this.columns = columns;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_columns', [sheetId]), error.toString()),
            () => { this.loadingColumns = false; }
        );
    }

    // noinspection JSMethodCanBeStatic
    private getColumnWidth(column: Report.Version2.Col): number {
        const stored = column.excelWidth;
        if ((stored === null) || stored < 50) {
            return 100;
        } else {
            return stored;
        }
    }
    // endregion


    // region Строки
    private loadingRows = false;
    // noinspection JSMismatchedCollectionQueryUpdate
    private rows: Array<Record<string, unknown>> = [];
    private rowComponents: Array<Vue & RowComponent> = [];

    private resetRows(rows: Array<Record<string, unknown>>, resetRowComponents?: true) {
        this.rows = [...rows];

        const totalRow: Record<string, number | null> = {};
        this.columns.forEach((column, index) => {
            if (index === 0) return;
            totalRow[column.field] = null;
        });
        rows.forEach((row) => {
            this.columns.forEach((column) => {
                const field = column.field;
                const rowValue = row[field];
                if (typeof rowValue === 'number') {
                    const savedValue = totalRow[field];
                    if (typeof savedValue === 'number') {
                        totalRow[field] = savedValue + rowValue;
                    } else {
                        totalRow[field] = rowValue;
                    }
                } else {
                    // noinspection JSIncompatibleTypesComparison
                    if (totalRow[field] === undefined) {
                        totalRow[field] = null;
                    }
                }
            });
        });
        this.totalRow = totalRow;

        setTimeout(() => {
            if (resetRowComponents) {
                this.rowComponents.forEach(rowComponent => {
                    rowComponent.resetRow();
                });
            }
        });
    }

    private reloadRowsIfPossible() {
        if ((!this.loadingRows) && (this.sheetId !== null)) this.reloadRows();
    }

    private reloadRows() {
        if (this.loadingRows) {
            console.error('Cannot load rows - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot load rows - sheet ID is null');
            return;
        }

        this.loadingRows = true;
        this.rows = [];
        this.totalRow = {};
        Ax<Array<Record<string, unknown>>>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/rows` },
            data => { this.resetRows(data); },
            error => {
                this.toast('danger', this.i18n.translate('error.cannot_load_rows', [sheetId]), error.toString());
                this.rows = [];
            },
            () => { this.loadingRows = false; }
        );
    }

    private updateRowComponents() {
        const rowComponents: Array<Vue & RowComponent> = [];

        const rowRefs = this.$refs.rows;
        if (Array.isArray(rowRefs)) {
            rowRefs.forEach((rowRef) => {
                const typedComponent = (rowRef as unknown as (Vue & RowComponent));
                rowComponents.push(typedComponent);
            });
        } else if (rowRefs instanceof Vue) {
            const typedComponent = (rowRefs as unknown as (Vue & RowComponent));
            rowComponents.push(typedComponent);
        }

        this.rowComponents = rowComponents;
    }
    // endregion


    // region Итоговая строка
    private totalRow: Record<string, number | null> = {};

    private getValueOfTotal(column: Report.Version2.Col): unknown {
        const value = this.totalRow[column.field];

        if (typeof value === 'number') {
            return this.numberFormat.format(value);
        }

        return value;
    }
    // endregion
}
