// noinspection DuplicatedCode

import { Report } from '../../types';
import * as common from './controllers-common';


interface Shared {
    /** Пособие на оздоровление государственных и гражданских служащих: Сумма* */
    bfWellnessColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
    bfEcoDisasterHealthColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
    bfEcoDisasterHealth113SrColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Подъемное пособие при служебном перемещении */
    bfMovementLiftingColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за вредные и опасные условия труда, сумма */
    bfOthGovServGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2024 год  сумма
     */
    bfLsadsaspjradetodmsc24ColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2025 год  сумма
     */
    bfLsadsaspjradetodmsc25ColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2026 год  сумма
     */
    bfLsadsaspjradetodmsc26ColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2027 год  сумма
     */
    bfLsadsaspjradetodmsc27ColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
     * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
     */
    bfFiredColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за вредные и опасные условия труда, сумма */
    cmHarmfulHazardousConditionsColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за особые условия труда, сумма */
    cmSpecialWorkConditionsColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за особые условия труда (коэффициент к ДО) (единовременная выплата по 113 специфике), сумма */
    compSpecialWorkConditionsSum113SrColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Сумма пособий на оздоровление */
    bfYearSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Итого */
    totalSumColGroup: Report.SubprogramDist.ColGroup | undefined;
}


const codes = {
    /** Сумма должностных окладов в месяц* */
    salaryMonthSum: 'l__salary_month_sum',

    /** Пособие на оздоровление государственных и гражданских служащих: Сумма */
    bfWellness: 'l__bf_wellness',

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
    bfEcoDisasterHealth: 'l__bf_eco_disaster_health',

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия (коэффициент к ДО) для 113 формы */
    bfEcoDisasterHealth113Sr: 'l__bf_eco_disaster_health_113_sr',

    /** Подъемное пособие при служебном перемещении, */
    bfMovementLifting: 'l__bf_movement_lifting',

    /** Компенсация за вредные и опасные условия труда, сумма */
    bfOthGovServ: 'l__bf_oth_gov_serv',

    /**
     * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
     * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
     */
    bfFired: 'l__bf_fired',

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2024 год  сумма
     */
    bfLsadsaspjradetodmsc24: 'l__bf_lsadsaspjradetodmsc_24',

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2025 год  сумма
     */
    bfLsadsaspjradetodmsc25: 'l__bf_lsadsaspjradetodmsc_25',

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2026 год  сумма
     */
    bfLsadsaspjradetodmsc26: 'l__bf_lsadsaspjradetodmsc_26',

    /**
     *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
     *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2026 год  сумма
     */
    bfLsadsaspjradetodmsc27: 'l__bf_lsadsaspjradetodmsc_27',

    /** Компенсация за вредные и опасные условия труда, сумма */
    cmHarmfulHazardousConditions: 'l__cm_harmful_hazardous_conditions',

    /** Компенсация за особые условия труда, сумма */
    cmSpecialWorkConditions: 'l__cm_special_work_conditions',

    /** Компенсация за особые условия труда (коэффициент к ДО) (единовременная выплата по 113 специфике), сумма */
    compSpecialWorkConditions113Sr: 'l__comp_special_work_conditions_113_sr',

    /** Сумма пособий на оздоровление */
    bfYearSum: 'l__bf_year_sum',

    /** Итого по специфике 113 */
    total: 'l__total',
};


const defaultExport: Report.SubprogramDist.Controller = common.createControllerForSubprogramRecalculation<Shared>(
    (columns, colGroups) => ({
        bfWellnessColGroup: colGroups.get(codes.bfWellness),
        bfEcoDisasterHealthColGroup: colGroups.get(codes.bfEcoDisasterHealth),
        bfEcoDisasterHealth113SrColGroup: colGroups.get(codes.bfEcoDisasterHealth113Sr),
        bfMovementLiftingColGroup: colGroups.get(codes.bfMovementLifting),
        bfOthGovServGroup: colGroups.get(codes.bfOthGovServ),
        bfFiredColGroup: colGroups.get(codes.bfFired),
        bfLsadsaspjradetodmsc24ColGroup: colGroups.get(codes.bfLsadsaspjradetodmsc24),
        bfLsadsaspjradetodmsc25ColGroup: colGroups.get(codes.bfLsadsaspjradetodmsc25),
        bfLsadsaspjradetodmsc26ColGroup: colGroups.get(codes.bfLsadsaspjradetodmsc26),
        bfLsadsaspjradetodmsc27ColGroup: colGroups.get(codes.bfLsadsaspjradetodmsc27),
        cmHarmfulHazardousConditionsColGroup: colGroups.get(codes.cmHarmfulHazardousConditions),
        cmSpecialWorkConditionsColGroup: colGroups.get(codes.cmSpecialWorkConditions),
        compSpecialWorkConditionsSum113SrColGroup: colGroups.get(codes.compSpecialWorkConditions113Sr),
        bfYearSumColGroup: colGroups.get(codes.bfYearSum),
        totalSumColGroup: colGroups.get(codes.total),
    }),
    (readNumber, setNumber, columns, colGroups, subprograms, fields, shared) => {
        const {
            bfWellnessColGroup,
            bfEcoDisasterHealthColGroup,
            bfEcoDisasterHealth113SrColGroup,
            bfMovementLiftingColGroup,
            bfOthGovServGroup,
            bfFiredColGroup,
            bfLsadsaspjradetodmsc24ColGroup,
            bfLsadsaspjradetodmsc25ColGroup,
            bfLsadsaspjradetodmsc26ColGroup,
            bfLsadsaspjradetodmsc27ColGroup,
            cmHarmfulHazardousConditionsColGroup,
            cmSpecialWorkConditionsColGroup,
            compSpecialWorkConditionsSum113SrColGroup,
            bfYearSumColGroup,
            totalSumColGroup,
        } = shared;

        /** Пособие на оздоровление государственных и гражданских служащих: Сумма* */
        const bfWellness = readNumber(bfWellnessColGroup);

        /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
        const bfEcoDisasterHealth = readNumber(bfEcoDisasterHealthColGroup);

        /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия (коэффициент к ДО) для 113 формы */
        const bfEcoDisasterHealth113Sr = readNumber(bfEcoDisasterHealth113SrColGroup);

        /** Подъемное пособие при служебном перемещении */
        const bfMovementLifting = readNumber(bfMovementLiftingColGroup);

        /** Компенсация за вредные и опасные условия труда, сумма */
        const bfOthGovServ  = readNumber(bfOthGovServGroup);

        /**
         *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
         *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2024 год  сумма
         */
        const bfLsadsaspjradetodmsc24 = readNumber(bfLsadsaspjradetodmsc24ColGroup);

        /**
         *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
         *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2025 год  сумма
         */
        const bfLsadsaspjradetodmsc25 = readNumber(bfLsadsaspjradetodmsc25ColGroup);

        /**
         *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
         *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2026 год  сумма
         */
        const bfLsadsaspjradetodmsc26 = readNumber(bfLsadsaspjradetodmsc26ColGroup);

        /**
         *  Пособие единовременное при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий,
         *  а также единовременное пособие при увольнении с военной службы военнослужащим срочной службы на 2026 год  сумма
         */
        const bfLsadsaspjradetodmsc27 = readNumber(bfLsadsaspjradetodmsc27ColGroup);

        /**
         * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
         * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
         */
        const bfFired = readNumber(bfFiredColGroup);

        /** Компенсация за вредные и опасные условия труда, сумма */
        const cmHarmfulHazardousConditions = readNumber(cmHarmfulHazardousConditionsColGroup);

        /** Компенсация за особые условия труда, сумма */
        const cmSpecialWorkConditions = readNumber(cmSpecialWorkConditionsColGroup);

        /** Компенсация за особые условия труда, сумма */
        const compSpecialWorkConditions113Sr = readNumber(compSpecialWorkConditionsSum113SrColGroup);

        /** Сумма пособий на оздоровление */
        const totalBfYearSum: number = (
            (bfWellness || 0) + // Сумма должностных окладов в месяц * 2
            (bfEcoDisasterHealth || 0) +
            (bfEcoDisasterHealth113Sr || 0)
        );

        /** Итого по специфике 113 */
        const totalSum: number = (
            (totalBfYearSum || 0) +
            (bfMovementLifting || 0)+
            (bfOthGovServ || 0)+
            (bfFired || 0) +
            (bfLsadsaspjradetodmsc24 || 0) +
            (bfLsadsaspjradetodmsc25 || 0) +
            (bfLsadsaspjradetodmsc26 || 0) +
            (bfLsadsaspjradetodmsc27 || 0) +
            (cmHarmfulHazardousConditions || 0)+
            (cmSpecialWorkConditions || 0) +
            (compSpecialWorkConditions113Sr || 0)
        );

        if (bfYearSumColGroup !== undefined)
            setNumber(bfYearSumColGroup, totalBfYearSum);
        if (totalSumColGroup !== undefined)
            setNumber(totalSumColGroup, totalSum);
    },
);
export default defaultExport;